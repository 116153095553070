import { FormProvider } from 'react-hook-form'
import * as validation from 'validation/index'

import useProcessForm from "hooks/useProcessForm"
import useReactHookForm from "hooks/useReactHookForm"

import {
  Input,
  DatePicker,
  TextField,
  FieldWrapper,
  SubmitButton
} from 'components/input-fields/_formComponents'

export default function Page1Company() {
  const { errorMessage, validateAndSaveForm } = useProcessForm()
  const methods = useReactHookForm({
    schema: validation.page1Company.schema
  })
  const { formState, handleSubmit } = methods

  function saveData(data) {
    validateAndSaveForm({
      formData: data,
      schema: 'page1Company',
      currentPage: 2
    })
  }

  return (
    <div className="form-content__wrapper">
      <FieldWrapper>
        <h2>Hakijayrityksen tiedot</h2>
      </FieldWrapper>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(saveData)}>
          <Input
            label="Y-tunnus *"
            fieldName="companyId"
            required={true}
          />

          <Input
            label="Yrityksen nimi *"
            fieldName="companyName"
            required={true}
          />

          <Input
            label="Sähköposti *"
            fieldName="companyEmail"
            required={true}
            input={{
              type: 'email',
            }}
          />

          <Input
            label="Puhelinnumero *"
            fieldName="companyPhone"
            required={true}
            errorMessage={'Syöta puhelinnumero muodossa +358....'}
          />

          <DatePicker
            label="Työsuhteen alkamispäivä *"
            fieldName="employementStartDate"
            required={true}
            input={{
              maxDate: new Date()
            }}
          />

          {errorMessage &&
            <TextField>
              <p className="color-red">{errorMessage}</p>
            </TextField>
          }

          <SubmitButton>Seuraava</SubmitButton>
        </form>
      </FormProvider>
    </div>
  );
}
