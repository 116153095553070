import useFormCtx from 'hooks/useFormCtx'

import PageNumber from './PageNumber'

export default function PageHighlighter() {
  const { formCtxState } = useFormCtx()

  const currentPage = formCtxState.currentPage
  const pages = [
    {
      page: 1,
      title: 'Henkilötiedot',
    },
    {
      page: 2,
      title: 'Lisätiedot',
    },
    {
      page: 3,
      title: 'Ajoneuvon tiedot ja rahoitus',
    },
    {
      page: 4,
      title: 'Valmis',
    },
  ]

  return (
    <div className="page-highlighter">
      {pages.map(({page, title}) => {
        const isCompleted = currentPage > page ? true : false

        return <PageNumber
          key={page}
          page={page}
          title={title}
          isCompleted={isCompleted}
        />
      })}
    </div>
  )
}