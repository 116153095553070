import FieldWrapper from 'components/input-fields/FieldWrapper'
import Button from 'components/global/buttons/Button'

export default function SubmitButton({ input, children, isloading }) {
  const attributes = {
    ...input,
    type: 'submit',
  }

  return (
    <FieldWrapper>
      <Button input={attributes} isloading={isloading}>{children}</Button>
    </FieldWrapper>
  )
}