import { useState } from 'react'
import axios from 'axios'

export default function useUserVerification() {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  function verifyUser(data, successCallback) {
    setIsLoading(true)
    setHasError(false)

    axios.post('/get-user-data', data)
      .then(function (response) {
        if(response.data.success === false) {
          setHasError(true)
          return
        }

        successCallback(response)
      })
      .catch(function (error) {
        setHasError(true)
      })
      .then(() => {
        setIsLoading(false)
      })
  }

  return {
    verifyUser,
    isLoading,
    hasError
  }
}