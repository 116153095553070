import { useCallback } from 'react'
import { FormProvider } from 'react-hook-form'
import * as validation from 'validation/index'

import useFieldValidate from 'hooks/useFieldValidate'
import useReactHookForm from "hooks/useReactHookForm"
import useProcessForm from "hooks/useProcessForm"

import {
  Select,
  Input,
  Radio,
  DatePicker,
  TextField,
  FieldWrapper,
  SubmitButton
} from 'components/input-fields/_formComponents'

export default function Page2() {
  const { errorMessage, validateAndSaveForm } = useProcessForm()
  const methods = useReactHookForm({
    schema: validation.page2.schema
  })
  const { handleSubmit, watch, resetField } = methods

  const watchResidenceType = watch('residenceType')
  const watchEmployement = watch('employement')

  useFieldValidate(useCallback((watchValue) => {
    if (watchValue !== 'Muu') {
      resetField('residenceTypeAdditionalInfo')
    }
  }, [resetField]), watchResidenceType)


  useFieldValidate(useCallback((watchValue) => {
    if (watchValue !== 'Muu') {
      resetField('residenceTypeAdditionalInfo')
    }
  }, [resetField]), watchResidenceType)


  useFieldValidate(useCallback((watchValue) => {
    if (watchValue !== 'Määräaikainen') {
      resetField('employementEndDate')
    }
  }, [resetField]), watchEmployement)

  function saveData(data) {
    validateAndSaveForm({
      formData: data,
      schema: 'page2',
      currentPage: 3,
    })
  }

  return (
    <div className="form-content__wrapper">
      <FieldWrapper>
        <h2>Lisätiedot</h2>
      </FieldWrapper>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(saveData)}>
        <Select
            label="Siviilisääty *"
            fieldName="maritalStatus"
            options={validation.page2.maritalStatusOptions}
            required={true}
          />

          <Select
            label="Asevelvollisuus *"
            fieldName="conscription"
            options={validation.page2.conscriptionOptions}
            required={true}
          />

          <Input
            label="Velanhoitomenot kuukaudessa *"
            fieldName="debtExpensesPerMonth"
            required={true}
          />

          <Select
            label="Työsuhde *"
            fieldName="employement"
            options={validation.page2.employementOptions}
            required={true}
          />

          <DatePicker
            label="Työsuhteen alkamispäivä *"
            fieldName="employementStartDate"
            required={true}
            input={{
              maxDate: new Date()
            }}
          />

          {watchEmployement === 'Määräaikainen' &&
            <DatePicker
              label="Työsuhteen päättymispäivä *"
              fieldName="employementEndDate"
              required={true}
            />
          }

          <Select
            label="Asumismuoto *"
            fieldName="residenceType"
            options={validation.page2.residenceTypeOptions}
            required={true}
          />

          {watchResidenceType === 'Muu' &&
            <Input
              label="Kerro lisätietoja asumismuodosta *"
              fieldName="residenceTypeAdditionalInfo"
              required={true}
            />
          }

          <Input
            label="Työnantaja *"
            fieldName="employer"
            required={true}
          />

          <Input
            label="Ammatti *"
            fieldName="profession"
            required={true}
          />

          <Input
            label="Bruttotulot kuukaudessa *"
            fieldName="grossMonthlyIncome"
            required={true}
          />

          {/** 
           * If values are changed they have to be changed everywhere, eg. in validation 
           * */}
          <Radio
            label="Onko hakija tai hakijan lähipiiriin kuuluva henkilö toiminut poliittisesti vaikutusvaltaisessa tehtävässä viimeisen vuoden aikana? *"
            fieldName="isPoliticallyActive"
            required={true}
            options={[
              'Kyllä',
              'Ei',
            ]}
          />

          {errorMessage &&
            <TextField>
              <p className="color-red">{errorMessage}</p>
            </TextField>
          }

          <SubmitButton>Seuraava</SubmitButton>
        </form>
      </FormProvider>
    </div>
  )
}