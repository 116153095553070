import useFormCtx from 'hooks/useFormCtx'

import {
  FieldWrapper,
} from 'components/input-fields/_formComponents'
import ButtonLink from 'components/global/buttons/ButtonLink'

export default function Page5() {
  const { formCtxDispatch } = useFormCtx()

  function handleResetForm(e) {
    e.preventDefault()

    formCtxDispatch({
      type: 'RESET_ALL',
    })
  }

  return (
    <div className="form-content__wrapper">
      <FieldWrapper>
        <h2>Hakemus on valmis!</h2>

        <ButtonLink onClick={handleResetForm} to="/">Takaisin etusivulle</ButtonLink>
      </FieldWrapper>
    </div>
  )
}