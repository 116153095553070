import { useFormContext } from "react-hook-form"

import FieldWrapper from './FieldWrapper'
import classes from './Checkbox.module.scss'

export default function Checkbox({ label, fieldName, errorMessage, required, input }) {
  const { register, formState: { errors } } = useFormContext()

  if(!errorMessage) {
    errorMessage = 'Tämä kenttä on pakollinen'
  }

  return (
    <FieldWrapper>
      <div className={classes['input-checkbox']}>
        <input type="checkbox" id={fieldName} {...input} {...register(fieldName, {
          required: required,
        })} />

        <label htmlFor={fieldName}>{label}</label>
      </div>

      {errors[fieldName] && <span className="error-message">{errorMessage}</span>}
    </FieldWrapper>
  )
}