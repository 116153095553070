import { useState, useEffect } from 'react'
import useFormCtx from 'hooks/useFormCtx'
import useProcessForm from 'hooks/useProcessForm'
import useUserVerification from 'hooks/useUserVerification'
import useUrlParams from 'hooks/useUrlParams'
import { useNavigate } from 'react-router-dom'
import ButtonLink from 'components/global/buttons/ButtonLink'
import LoadingIcon from 'components/global/LoadingIcon'

export default function TeliaVerification() {
  const [paramError, setParamError] = useState(false)
  const { formCtxDispatch } = useFormCtx()
  const { appendFormData } = useProcessForm()
  const { verifyUser, isLoading, hasError } = useUserVerification()
  const { params } = useUrlParams()
  const navigate = useNavigate()
  const responseCode = params.get('code')

  useEffect(() => {
    
    if(responseCode == null) {
      setParamError(true)
      return
    }

    verifyUser({
      code: responseCode
    }, (response) => {
      if (response.data.success === true) {
        appendFormData({
          formData: {
            name: response.data.data.fullName,
            personalID: response.data.data.personalID,
          },
          currentPage: 1
        })
        formCtxDispatch({
          type: 'FORM_TYPE',
          payload: {
            formType: 'private'
          }
        })

        navigate('/lomake')
      }
    })

    // This is for local testing:
    // appendFormData({
    //   formData: {
    //     name: 'Test Testing',
    //     personalID: '000000-0000',
    //   },
    //   currentPage: 1
    // })

    // formCtxDispatch({
    //   type: 'FORM_TYPE',
    //   payload: {
    //     formType: 'private'
    //   }
    // })

    // navigate('/lomake')
    // // }
    //  })
    // test ends -> for testing, comment below
  }, [])

  return (
    <div className="form-content__wrapper">
      {isLoading && <>
        <LoadingIcon />
        <p className='text-center'>Odota hetki...</p>
      </>}

      {(paramError || hasError) && <>
        <p>Tapahtui odottamaton virhe, yritä uudelleen</p>
        <ButtonLink to="/">Takaisin etusivulle</ButtonLink>
      </>}
    </div>
  )
}