import levelupLogo from 'assets/images/levelup-logo.svg'

export default function Footer() {
  return (
    <footer>
      <div className="footer-info">
        <p>Palvelun tuottaa:</p>
        <p>Suomen Autorahasto Oy</p>
          <p>Y-tunnus: 2625703-1</p>
          <p>Laaksolahdentie 41, 02730 Espoo</p>
          <p>0103364111 tai info@autoraha.fi</p>
      </div>

      <div className="footer-levelup">
        <p className="site-by-levelup">Toteutus <img src={levelupLogo} alt="LevelUp Oy" /> <a target="_blank" rel="noreferrer" href="https://levelup.fi/">LevelUp Oy</a></p>
        <p>Copyright © 2024 Suomen Autorahasto Oy</p>
      </div>

      <div className="footer-help">
        <p>
          <a className="help-link" href="/">
            ?
          </a>
        </p>
      </div>
    </footer>
  )
}