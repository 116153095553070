import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import * as validation from 'validation/index'

import useFormCtx from 'hooks/useFormCtx'
import useFormSend from 'hooks/useFormSend'
import useReactHookForm from "hooks/useReactHookForm"

import { 
  Checkbox,
  TextField,
  FieldWrapper,
  SubmitButton
} from 'components/input-fields/_formComponents'

export default function Page4() {
  const { sendFormData, hasError, isComplete, isLoading } = useFormSend()
  const { formCtxState, formCtxDispatch } = useFormCtx()
  const methods = useReactHookForm({
    schema: validation.page4.schema
  })
  const { formState, handleSubmit } = methods

  function saveData() {
    sendFormData({
      formData: formCtxState.formData,
      formType: formCtxState.formType
    })
  }

  useEffect(() => {
    if(!hasError && isComplete) {
      formCtxDispatch({
        type: 'DATA_AND_CURRENT_PAGE',
        payload: {
          formData: {},
          currentPage: 5
        }
      })
    }
  }, [hasError, isComplete])

  return (
    <div className="form-content__wrapper">
      <FieldWrapper>
        <h2>Valmis</h2>
      </FieldWrapper>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(saveData)}>
          <Checkbox
            label="Hyväksyn, että luotonantaja tarkistaa tulotiedot sekä olemassa olevien luottojen tiedot positiivisesta luottorekisteristä ja lisää sinne uusien myönnettyjen lainojen tiedot. *"
            fieldName="acceptCreditCheck"
            required={true}
          />

          <Checkbox
            label="Annettuja tietoja ei ole mahdollista muuttaa myöhemmin. Vahvista, että olet tarkistanut tiedot. *"
            fieldName="confirmAll"
            required={true}
          />

          {hasError &&
            <TextField>
              <p className="color-red">Tapahtui virhe</p>
            </TextField>
          }

          <SubmitButton input={{
            disabled: (!formState.isValid || isLoading) ? 'disabled' : ''
          }} isloading={isLoading}>Lähetä</SubmitButton>
        </form>
      </FormProvider>
    </div>
  )
}