import { FormProvider } from 'react-hook-form'
import * as validation from 'validation/index'

import useFormCtx from 'hooks/useFormCtx'
import useProcessForm from "hooks/useProcessForm"
import useReactHookForm from "hooks/useReactHookForm"

import {
  Select,
  Input,
  Radio,
  TextField,
  FieldWrapper,
  SubmitButton
} from 'components/input-fields/_formComponents'

export default function Page1() {
  const { formCtxState } = useFormCtx()
  const { formData } = formCtxState

  const { validateAndSaveForm, errorMessage } = useProcessForm()
  const methods = useReactHookForm({
    schema: validation.page1.schema
  })
  const { formState, handleSubmit } = methods

  function saveData(data) {
    validateAndSaveForm({
      formData: data,
      schema: 'page1',
      currentPage: 2,
    })
  }

  return (
    <div className="form-content__wrapper">
      <FieldWrapper>
        <h2>Henkilötiedot</h2>
      </FieldWrapper>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(saveData)}>
          <TextField label="Nimi *">
            {formData.name}
          </TextField>

          <TextField label="Henkilötunnus *">
            {formData.personalID}
          </TextField>

          <Input
            label="Osoite *"
            fieldName="address"
            required={true}
          />

          <Input
            label="Kaupunki *"
            fieldName="city"
            required={true}
          />

          <Input
            label="Postinumero *"
            fieldName="postalCode"
            required={true}
          />

          <Input
            label="Sähköposti *"
            fieldName="email"
            required={true}
            input={{
              type: 'email',
            }}
          />

          <Input
            label="Puhelinnumero *"
            fieldName="phone"
            required={true}
            errorMessage={'Syöta puhelinnumero muodossa +358....'}
          />

          {errorMessage &&
            <TextField>
              <p className="color-red">{errorMessage}</p>
            </TextField>
          }

          <SubmitButton>Seuraava</SubmitButton>
        </form>
      </FormProvider>
    </div>
  );
}
