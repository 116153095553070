import { useFormContext } from 'react-hook-form'

import FieldWrapper from './FieldWrapper'

export default function Input({ label, fieldName, errorMessage, required, input = {} }) {
  const { register, formState: { errors } } = useFormContext()

  if (!input.hasOwnProperty('type')) {
    input.type = 'text'
  }

  if(!errorMessage) {
    errorMessage = 'Tämä kenttä on pakollinen'
  }

  return (
    <FieldWrapper label={label}>
      <input  className="input-field" {...input} {...register(fieldName, {
        required,
      })} />

      {errors[fieldName] && <span className="error-message">{errorMessage}</span>}
    </FieldWrapper>
  )
}