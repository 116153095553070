import LoadingIcon from 'components/global/LoadingIcon'

export default function ButtonContent({input, children, isloading}) {
  return (
    <>
      {children}

      {isloading && <LoadingIcon />}
    </>
  )
}