const phoneRegExp = /^\+(?:[0-9]●?){6,14}[0-9]$/

const maritalStatusOptions = [
  "Naimisissa/Rekisteröidyssä parisuhteessa",
  "Naimaton",
  "Avoliitossa",
  "Eronnut",
  "Leski",
]

const conscriptionOptions = [
  "Suoritettu",
  "Suorittamatta",
  "Vapautettu",
  "Ei asevelvollinen",
]

const employementOptions = [
  "Vakituinen",
  "Määräaikainen",
  "Yrittäjä",
  "Eläkeläinen",
  "Opiskelija",
  "Työtön",
]

const residenceTypeOptions = [
  "Oma",
  "Vuokra",
  "Asumisoikeus",
  "Vanhempien luona",
  "Muu",
]

const paymentDurationOptions = [
  '12 kk',
  '24 kk',
  '36 kk',
  '48 kk',
  '60 kk',
  '72 kk',
]

export {
  phoneRegExp,
  maritalStatusOptions,
  conscriptionOptions,
  employementOptions,
  residenceTypeOptions,
  paymentDurationOptions,
}