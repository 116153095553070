import { useFormContext } from 'react-hook-form'

import FieldWrapper from './FieldWrapper'

export default function Select({ label, fieldName, errorMessage, options = [], required, input }) {
  const { register, formState: { errors } } = useFormContext()

  if(!errorMessage) {
    errorMessage = 'Tämä kenttä on pakollinen'
  }

  return (
    <FieldWrapper label={label}>
      <select defaultValue="" className="input-field" {...register(fieldName, { required: true })} {...input}>
        <option disabled value="">
          Valitse
        </option>

        {options.map(value => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>

      {errors[fieldName] && <span className="error-message">{errorMessage}</span>}
    </FieldWrapper>
  )
}