import { useDocumentTitle } from 'hooks/useDocumentTitle'

export default function PageHeader({ title }) {
  useDocumentTitle(title)

  return (
    <>
      <div className="page-header">
        <h1 className="page-header__title">{title}</h1>
      </div>
    </> 
  )
}