import ContentWrapper from 'components/global/wrapper/ContentWrapper'

import PageHeader from 'components/global/PageHeader'

import TeliaVerification from 'pages/form/TeliaVerification'

export default function Hakemus() {
  return (
    <>
      <PageHeader title="Rahoitushakemus – Suomen Autorahasto" />
      <ContentWrapper>
        <div className="content-container">
          <TeliaVerification />
        </div>
      </ContentWrapper >
    </>
  )
}