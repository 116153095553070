import * as yup from 'yup'
import { phoneRegExp } from '../constants.js'

const schema = yup.object().shape({
  companyId: yup.string().required(),
  companyName: yup.string().required(),
  companyEmail: yup.string().email().required(),
  companyPhone: yup.string().required().matches(phoneRegExp),
  employementStartDate: yup.date().required(),
})

export { schema }
