import * as yup from 'yup'

import { paymentDurationOptions } from '../constants.js'

const schema = yup.object().shape({
  carPrice: yup.string(),
  licensePlateNumber: yup.string(),
  carOdometer: yup.string(),
  paymentDuration: yup.string().oneOf(paymentDurationOptions),
  downPayment: yup.string(),
  monthlyInstallements: yup.string(),
  includeMaintenanceCoverage: yup.string().required().oneOf(['Kyllä', 'Ei']),
})

export { schema }