import { schema as page1Schema } from './pages/page1.js'
import { schema as page1CompanySchema } from './pages/page1Company.js'
import { schema as page2Schema } from './pages/page2.js'
import { schema as page2CompanySchema } from './pages/page2Company.js'
import { schema as page3Schema } from './pages/page3.js'
import { schema as page4Schema } from './pages/page4.js'

import { validateForm } from './functions.js'

import {
  maritalStatusOptions,
  conscriptionOptions,
  employementOptions,
  residenceTypeOptions,
  paymentDurationOptions,
} from './constants.js'

const page1 = {
  schema: page1Schema,
}

const page1Company = {
  schema: page1CompanySchema
}

const page2 = {
  schema: page2Schema,
  maritalStatusOptions,
  conscriptionOptions,
  employementOptions,
  residenceTypeOptions,
}

const page2Company = {
  schema: page2CompanySchema,
  employementOptions,
  residenceTypeOptions,
}

const page3 = {
  schema: page3Schema,
  paymentDurationOptions
}

const page4 = {
  schema: page4Schema
}

export {
  page1,
  page1Company,
  page2,
  page2Company,
  page3,
  page4,
  validateForm
}