import * as yup from 'yup'

const schema = yup.object().shape({
  address: yup.string().required(),
  city: yup.string().required(),
  postalCode: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
});

export { schema }
