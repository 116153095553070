import { useEffect } from 'react'
import { useNavigate } from "react-router-dom"

import PageHighlighter from 'components/page-highlighter/PageHighlighter'
import ContentWrapper from 'components/global/wrapper/ContentWrapper'

import PageHeader from 'components/global/PageHeader'
import TeliaVerification from 'pages/form/TeliaVerification'
import Page1 from 'pages/form/Page1'
import Page1Company from 'pages/form/Page1Company'
import Page2 from 'pages/form/Page2'
import Page2Company from 'pages/form/Page2Company'
import Page3 from 'pages/form/Page3'
import Page4 from 'pages/form/Page4'
import Page5 from 'pages/form/Page5'

import useFormCtx from 'hooks/useFormCtx'

export default function Form() {
  const { formCtxState } = useFormCtx()
  const navigate = useNavigate()

  const { currentPage, formType } = formCtxState

  const page1 = (formType === 'company') ? <Page1Company /> : <Page1 />
  const page2 = (formType === 'company') ? <Page2Company /> : <Page2 />

  /**
   * Alert user before leaving page
   */
  useEffect(() => {
    if (formType != null) {
      window.onbeforeunload = function () {
        return 'Are you sure you want to leave?';
      };
    }
  }, [formType])

  /**
   * Scroll to top of form on page change
   */
  useEffect(() => {
    const formWrapper = document.querySelector('.form-content__wrapper')
    formWrapper.scrollIntoView()
  }, [currentPage])

  useEffect(() => {
    if (formType == null) {
      navigate('/')
    }
  }, [formType, navigate])

  return (
    <>
      <PageHeader title="Rahoitushakemus – Suomen Autorahasto" />
      <ContentWrapper>
        <PageHighlighter />

        <div className="content-container">
          {currentPage === 0 && <TeliaVerification />}

          {currentPage === 1 && page1}

          {currentPage === 2 && page2}

          {currentPage === 3 && <Page3 />}

          {currentPage === 4 && <Page4 />}

          {currentPage === 5 && <Page5 />}
        </div>
      </ContentWrapper >
    </>
  )
}