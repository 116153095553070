import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import FormContextProvider from 'context/FormContext'

import FrontPage from 'pages/FrontPage'
import Form from 'pages/Form'
import Hakemus from 'pages/Hakemus'
import MaintenanceInsurance from 'pages/MaintenanceInsurance'

import Header from 'components/global/Header'
import Footer from 'components/global/Footer'

const App = () => {
  return (
    <div className="App">
      <Header />

      <FormContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<FrontPage />} />
            <Route path="/hakemus" element={<Hakemus />} />
            <Route path="/lomake" element={<Form />} />
            <Route path="/kunnossapitoturvan-sopimusehdot" element={<MaintenanceInsurance />} />
          </Routes>
        </Router>
      </FormContextProvider>

      <Footer />
    </div>
  );
}

export default App;
