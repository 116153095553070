import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

export default function useReactHookForm(options) {
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(options.schema),
  })

  return methods
}