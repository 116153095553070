import { useFormContext } from 'react-hook-form'

import FieldWrapper from './FieldWrapper'

import classes from './Radio.module.scss'

export default function Radio({ label, fieldName, errorMessage, required, options, input }) {
  const { register, formState: { errors } } = useFormContext()

  if(!errorMessage) {
    errorMessage = 'Tämä kenttä on pakollinen'
  }

  return (
    <FieldWrapper>
      <fieldset className={classes['fieldset']}>
        <legend>{label}</legend>

        <div className={classes['radio-options__wrapper']}>
          {options.map(value => {
            const id = `${fieldName}_${value}`

            return (
              <label htmlFor={id} key={value} className={classes['label']}>
                <input type="radio"  {...register(fieldName, {
                  required: required
                })} id={id} name={fieldName} value={value} />

                <span className={classes['checkmark']}></span>

                <span className={classes['label-text']}>{value}</span>
              </label>
            )
          })}
        </div>
      </fieldset>

      {errors[fieldName] && <span className="error-message">{errorMessage}</span>}
    </FieldWrapper>
  )
}