import ButtonContent from './ButtonContent'

export default function Button({ onClick, children, input, isloading }) {
  return (
    <button className="button" onClick={onClick} {...input}>
      <ButtonContent input={input} isloading={isloading}>
        {children}
      </ButtonContent>
    </button>
  )
}