import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form'
import * as validation from 'validation/index'

import useFieldValidate from 'hooks/useFieldValidate'
import useProcessForm from "hooks/useProcessForm"
import useReactHookForm from "hooks/useReactHookForm"

import {
  Select,
  Input,
  TextField,
  FieldWrapper,
  SubmitButton
} from 'components/input-fields/_formComponents'

export default function Page2Company() {
  const { errorMessage, validateAndSaveForm } = useProcessForm()
  const methods = useReactHookForm({
    schema: validation.page2Company.schema
  })
  const { resetField, watch } = methods

  const watchResidenceType = watch('residenceType')

  useFieldValidate(useCallback((watchValue) => {
    if (watchValue !== 'Muu') {
      resetField('residenceTypeAdditionalInfo')
    }
  }, [resetField]), watchResidenceType)

  function saveData(data) {
    validateAndSaveForm({
      formData: data,
      schema: 'page2Company',
      currentPage: 3
    })
  }

  return (
    <div className="form-content__wrapper">
      <FieldWrapper>
        <h2>Nimenkirjoitusoikeudellisen henkilön tiedot</h2>
      </FieldWrapper>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(saveData)}>
          <Input
            label="Nimi *"
            fieldName="name"
            required={true}
          />

          <Input
            label="Sähköposti *"
            fieldName="email"
            required={true}
            input={{
              type: 'email',
            }}
          />

          <Input
            label="Puhelinnumero *"
            fieldName="phone"
            required={true}
            errorMessage={'Syöta puhelinnumero muodossa +358....'}
          />

          <Select
            label="Siviilisääty *"
            fieldName="maritalStatus"
            options={[
              "Naimisissa/Rekisteröidyssä parisuhteessa",
              "Naimaton",
              "Avoliitossa",
              "Eronnut",
              "Leski",
            ]}
            required={true}
          />

          <Select
            label="Asumismuoto *"
            fieldName="residenceType"
            options={[
              "Oma",
              "Vuokra",
              "Asumisoikeus",
              "Vanhempien luona",
              "Muu",
            ]}
            required={true}
          />

          {watchResidenceType === 'Muu' &&
            <Input
              label="Kerro lisätietoja asumismuodosta *"
              fieldName="residenceTypeAdditionalInfo"
              required={true}
            />
          }

          <Input
            label="Bruttotulot kuukaudessa *"
            fieldName="grossMonthlyIncome"
            required={true}
          />

          {errorMessage &&
            <TextField>
              <p className="color-red">{errorMessage}</p>
            </TextField>
          }

          <SubmitButton>Seuraava</SubmitButton>
        </form>
      </FormProvider>
    </div>
  );
}
