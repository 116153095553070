import * as yup from 'yup'
import { phoneRegExp, maritalStatusOptions, residenceTypeOptions } from '../constants.js'

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required().matches(phoneRegExp),
  maritalStatus: yup.string().required().oneOf(maritalStatusOptions),
  residenceType: yup.string().required().oneOf(residenceTypeOptions),
  grossMonthlyIncome: yup.string().required()
})

export { schema }
