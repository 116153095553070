import classes from './FieldWrapper.module.scss'

export default function FieldWrapper({ label, children }) {
  return (
    <div className={classes['field-wrapper']}>
      {label && <label>{label}</label>}
      
      <div className={classes['input-wrapper']}>
        {children}
      </div>
    </div>
  )
}