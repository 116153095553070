import { useState } from 'react'
import useFormCtx from './useFormCtx'
import axios from 'axios'

export default function useProcessForm() {
  const errorMessageText = 'An error has occured, please check fields'

  const [errorMessage, setErrorMessage] = useState(null)
  const { formCtxState, formCtxDispatch } = useFormCtx()

  /**
   * Perform external form validation, and save form data on success
   * 
   * @param {*} body 
   * @returns 
   */
  async function validateAndSaveForm(body) {
    setErrorMessage(null)

    const response = await axios.post('/validate-form', body)
      .then(function (response) {
        if (response.data.success === true) {
          appendFormData(body)
        } else {
          setErrorMessage(errorMessageText)
        }

        return response
      })
      .catch(error => {
        setErrorMessage(errorMessageText)
      })
      .then(data => data)

    return response
  }

  /**
   * Save form data to context
   * 
   * @param {*} data 
   */
  function appendFormData(data) {
    const formData = {
      ...formCtxState.formData,
      ...data.formData
    }

    formCtxDispatch({
      type: 'DATA_AND_CURRENT_PAGE',
      payload: {
        formData: formData,
        currentPage: data.currentPage
      }
    })
  }

  return {
    validateAndSaveForm,
    appendFormData,
    errorMessage
  }
}