import PageHeader from 'components/global/PageHeader'

import GoSafe from './maintenance-insurance/GoSafe'

import goSafeBasic from 'assets/images/gosafe-basic.svg'
import goSafePremium from 'assets/images/gosafe-premium.svg'
import goSafeExtended from 'assets/images/gosafe-extended.svg'
import goSafeElectric from 'assets/images/gosafe-electric.svg'

export default function MaintenanceInsurance() {
  return (
    <>
      <PageHeader title="Kunnossapitoturvan sopimusehdot" />

      <div className="gosafe__wrapper layout-horizontal">
        <GoSafe image={goSafeBasic} price="29 €/kk" />
        <GoSafe image={goSafePremium} price="29 €/kk" />
        <GoSafe image={goSafeExtended} price="29 €/kk" />
        <GoSafe image={goSafeElectric} price="29 €/kk" />
      </div>
    </>
  )
}