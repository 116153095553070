import checkmarkImg from 'assets/images/checkmark.svg'

export default function PageNumber({ page, title, isCompleted }) {
  const styles = isCompleted ? 'active' : ''
  const content = isCompleted ? <img src={checkmarkImg} alt="Checkmark" /> : page

  return (
    <div className="page-number__wrapper">
      <div className={`page-number ${styles}`}>
        <span>{content}</span>
      </div>
      {title}
    </div>
  )
}