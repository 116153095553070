import * as yup from 'yup'
import { maritalStatusOptions, conscriptionOptions, employementOptions, residenceTypeOptions } from '../constants.js'

const schema = yup.object().shape({
  maritalStatus: yup.string().required().oneOf(maritalStatusOptions),
  conscription: yup.string().required().oneOf(conscriptionOptions),
  debtExpensesPerMonth: yup.string().required(),
  employement: yup.string().required().oneOf(employementOptions),
  employementStartDate: yup.date().required(),
  residenceType: yup.string().required().oneOf(residenceTypeOptions),
  residenceTypeAdditionalInfo: yup.string().when('residenceType', ([residenceType], schema) => {
    return (residenceType === 'Muu') ? schema.required() : schema;
  }),
  employer: yup.string().required(),
  profession: yup.string().required(),
  grossMonthlyIncome: yup.string().required(),
  isPoliticallyActive: yup.string().required().oneOf(['Kyllä', 'Ei']),
})

export { schema }
