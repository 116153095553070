import { useContext } from "react";

import { FormContext } from 'context/FormContext'

export default function useFormCtx() {
  const context = useContext(FormContext)

  if (!context) {
    throw new Error("useAppState must be used within the AppProvider");
  }

  return context
}