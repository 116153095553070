import { Controller } from 'react-hook-form'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import FieldWrapper from './FieldWrapper'
import fi from 'date-fns/locale/fi';

export default function DatePicker({ label, fieldName, required, input }) {
  registerLocale('fi', fi)

  return (
    <FieldWrapper label={label}>
      <Controller
        name={fieldName}
        rules={{ required }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          // fieldState: { invalid, isTouched, isDirty, error },
          // formState,
        }) => (
          <ReactDatePicker
            className="input-field"
            required={required}
            selected={value}
            onChange={onChange}
            dateFormat="dd.MM.yyyy"
            showYearDropdown
            locale="fi"
            {...input}
          />
        )}
      />
    </FieldWrapper>
  )
}