import useFormCtx from 'hooks/useFormCtx'

import Link from 'components/global/buttons/Link'
import ButtonLink from 'components/global/buttons/ButtonLink'

export default function FrontPage() {
  const { formCtxDispatch } = useFormCtx()

  function setFormType(value) {
    formCtxDispatch({
      type: 'FORM_TYPE',
      payload: {
        formType: value
      }
    })
  }

  return (
    <div className="frontpage">

      <div className="frontpage-bg">
        <div className="frontpage-banner">
          <h1>Rahoitus&shy;hakemus</h1>
          <p>Autoraha rahoittaa yksityisten ja yritysten välistä autokauppaa, poislukien autoliikkeiden autot.</p>
          <p>Luottamuksella jo vuodesta 2014.</p>
        </div>
      </div>

      <div className="frontpage-wrapper">
        <div className='layout-vertical'>
          <div>
            <p>Voit hakea rahoitusta alla olevista linkeistä, joista siirrytään vahvaan tunnistautumiseen.
              Käytämme tietojesi käsittelyssä vahvaa tunnistautumista.</p>
          </div>
        </div>

        <div className='layout-vertical'>
          <Link href={'https://tunnistus.telia.fi/uas/oauth2/authorization?response_type=code&scope=openid&client_id=757f4d22-0cc1-4ee3-837b-168b9edea051&scope=openid&redirect_uri=https://rahoitushakemus.autoraha.fi/hakemus'}>
            Hae alustava luottopäätös yksityiselle
          </Link>

          {/* This is for local testing: 
            <ButtonLink to={'/hakemus'}>
            Hae alustava luottopäätös yksityiselle
          </ButtonLink> */}

          <ButtonLink onClick={() => setFormType('company')} to={'/lomake'}>
            Hae alustava luottopäätös yritykselle
          </ButtonLink>
        </div>

        <div className='layout-vertical mobile-text'>
        <p>Autoraha rahoittaa yksityisten ja yritysten välistä autokauppaa, poislukien autoliikkeiden autot.</p>
        <p>Luottamuksella jo vuodesta 2014.</p>
        </div>
      </div>
    </div>
  )
}