export default function GoSafe({image, price, link}) {
  return (
    <div className="gosafe-item__wrapper">
      <img src={image} alt="GoSafe Basic" />

      <p style={{marginBottom: 0}}>Hinta alkaen</p>

      <p className="gosafe__price">{price}</p>

      <a className="gosafe__readmore" href={link}>{`Lue lisää >`}</a>
    </div>
  )
}