import { Link } from "react-router-dom";
import ButtonContent from './ButtonContent'

export default function ButtonLink({ onClick, to, children, input, isloading }) {
  return (
    <Link onClick={onClick} className="button" to={to}>
      <ButtonContent input={input} isloading={isloading}>
        {children}
      </ButtonContent>
    </Link>
  )
}