import classes from './TextField.module.scss'

import FieldWrapper from './FieldWrapper'

export default function TextField({ label, children}) {
  
  return (
    <FieldWrapper label={label}>
      <div className={classes['text']}>{children}</div>
    </FieldWrapper>
  )
}