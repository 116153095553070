import { useReducer, createContext } from "react";

const FormContext = createContext()

const defaultFormValue = {
  formData: {},
  currentPage: 1,
  formType: null,
}

function formReducer(state, { type, payload }) {
  switch (type) {
    case 'FORM_TYPE':
      return {
        ...state,
        formType: payload.formType
      }
    case 'CURRENT_PAGE':
      return {
        ...state,
        currentPage: payload.currentPage
      }
    case 'DATA':
      return {
        ...state,
        formData: payload.formData,
      }
    case 'DATA_AND_CURRENT_PAGE':
      return {
        ...state,
        formData: payload.formData,
        currentPage: payload.currentPage,
      }
    case 'RESET_ALL':
      return defaultFormValue

    default:
      return state
  }
}

/**
 * Form Context Provider wrapper
 * 
 * @param {*} param
 * @returns 
 */
export default function FormContextProvider({ children }) {
  const [formCtxState, formCtxDispatch] = useReducer(formReducer, defaultFormValue)

  const context = {
    formCtxState,
    formCtxDispatch,
  }

  return (
    <FormContext.Provider value={context}>
      {children}
    </FormContext.Provider>
  )
}

export {
  FormContext
}