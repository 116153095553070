import { FormProvider } from 'react-hook-form'
import * as validation from 'validation/index'
import { Link } from "react-router-dom";

import useProcessForm from "hooks/useProcessForm"
import useReactHookForm from "hooks/useReactHookForm"

import {
  Select,
  Input,
  Radio,
  TextField,
  FieldWrapper,
  SubmitButton
} from 'components/input-fields/_formComponents'

export default function Page3() {
  const { errorMessage, validateAndSaveForm } = useProcessForm()
  const methods = useReactHookForm({
    schema: validation.page3.schema
  })
  const { formState, handleSubmit, watch } = methods

  const watchPaymentDuration = watch('paymentDuration')

  function saveData(data) {
    validateAndSaveForm({
      formData: data,
      schema: 'page3',
      currentPage: 4
    })
  }

  let paymentAmount = ''

  if (watchPaymentDuration != null && watchPaymentDuration !== '') {
    const paymentDuration = watchPaymentDuration ? parseInt(watchPaymentDuration.replace('kk', '')) : ''

    paymentAmount = Math.ceil(599 / paymentDuration)
  } else {
    paymentAmount = ''
  }

  /**
   * Convert text to uppercase
   * 
   * @param {*} e 
   */
  function handleLicensePlateInput(e) {
    e.target.value = ('' + e.target.value).toUpperCase();
  }

  return (
    <div className="form-content__wrapper">
      <FieldWrapper>
        <h2>Ajoneuvon tiedot & rahoitus</h2>
      </FieldWrapper>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(saveData)}>
          <FieldWrapper>
            <h3>Ajoneuvon tiedot</h3>
          </FieldWrapper>
          <Input
            label="Kohteen hinta"
            fieldName="carPrice"
            required={false}
          />

          <Input
            label="Rekisterinumero"
            fieldName="licensePlateNumber"
            required={false}
            input={{
              onInput: handleLicensePlateInput
            }}
          />

          <Input
            label="Mittarilukema"
            fieldName="carOdometer"
            required={false}
          />

          <FieldWrapper> 
            <h3>Rahoitus</h3>
          </FieldWrapper>
          
          <Select
            label="Luottoaika"
            fieldName="paymentDuration"
            options={validation.page3.paymentDurationOptions}
            required={false}
          />

          <Input
            label="Käsiraha"
            fieldName="downPayment"
            required={false}
          />

          <Input
            label="Toivottu kuukausierä"
            fieldName="monthlyInstallements"
            required={false}
          />

          {paymentAmount &&
            <TextField>
              <p>Tarjoamme rahoitussopimukseesi maksullisen kunnossapitoturvan hintaan</p>

              <p className="large-text color-primary">{paymentAmount} €/kk</p>

              <p>Lisätietoja kunnossapitoturvasta löydät <Link to="/kunnossapitoturvan-sopimusehdot">täältä</Link>.</p>
            </TextField>
          }

          <Radio
            label="Haluatko sisällyttää rahoitussopimukseen maksullisen kunnossapitoturvan? *"
            fieldName="includeMaintenanceCoverage"
            required={true}
            options={[
              'Kyllä',
              'Ei',
            ]}
          />

          {errorMessage &&
            <TextField>
              <p className="color-red">{errorMessage}</p>
            </TextField>
          }

          <SubmitButton>Seuraava</SubmitButton>
        </form>
      </FormProvider>
    </div>
  )
}