import { useState } from 'react'
import axios from 'axios'

export default function useFormSend() {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  function sendFormData(data) {
    setHasError(false)
    setIsComplete(false)
    setIsLoading(true)

    axios.post('/create-lead', data)
      .then(function (response) {
        if (response.data.success === false) {
          setHasError(true)
        } else {
          setHasError(false)
        }
      })
      .catch(function (error) {
        setHasError(true)
        console.log(error)
      })
      .then(function (data) {
        setIsComplete(true)
        setIsLoading(false)
      })
  }

  return {
    sendFormData,
    hasError,
    isComplete,
    isLoading
  }
}